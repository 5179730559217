import styled from 'styled-components';

import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';

import MoreContentRequester from 'components/MoreContentRequester';
import CardBadge from 'components/shared/CardBadge';
import Escalation from 'models/Escalation';
import { Paginated } from 'models/Paginated';
import { colors } from 'styles/theme/colors';
import { BodySmallBold, Label, LabelBold } from 'styles/typography';
import CircleCheckIcon from 'svg/CircleCheckIcon';
import GroupIcon from 'svg/GroupIcon';
import PriorityFlag from 'svg/PriorityFlag';
import WarnIcon from 'svg/WarnIcon';

type TaskModalListType = {
  query: UseInfiniteQueryResult<InfiniteData<Paginated<Escalation>, unknown>, Error>;
  selectedRecordId: string;
  setSelectedRecordId: (id: string) => void;
  recordTypeLabel: string;
};
export default function TaskModalList(props: TaskModalListType) {
  const { query, selectedRecordId, setSelectedRecordId, recordTypeLabel } = props;

  return (
    <Container>
      <>
        {query.data?.pages.map((page) =>
          page.data.map((record: Escalation) => (
            <CardContainer
              key={record.id}
              onClick={() => setSelectedRecordId(record.id)}
              $active={record.id === selectedRecordId}>
              <CardHeader>
                <CardTitle>{record.patient.name}</CardTitle>
                {record.acknowledged ? (
                  <CircleCheckIcon color={colors.accentGreen} width={12} height={12} />
                ) : record.isDefault ? (
                  <WarnIcon color={colors.accentRed} width={12} height={12} />
                ) : (
                  <PriorityFlag color={colors.accentRed} width={12} height={12} />
                )}
              </CardHeader>
              <SummaryText>{record.summary}</SummaryText>
              <BottomInfoContainer>
                <CardBadge
                  text={`${record.group?.groupType.displayName} - ${record.group?.name}`}
                  renderIcon={() => <GroupIcon width={18} height={18} color={colors.black} />}
                />
              </BottomInfoContainer>
            </CardContainer>
          ))
        )}

        <MoreContentRequester resource={query} />

        {!query.isLoading && query.data?.pages?.[0]?.meta?.totalRecords === 0 && (
          <EmptyState>
            <InfoText>Any {recordTypeLabel.toLowerCase()} that require your attention will be listed here.</InfoText>
          </EmptyState>
        )}
      </>
    </Container>
  );
}

const InfoText = styled(BodySmallBold)`
  text-align: center;
  color: ${colors.black50};
`;

const SummaryText = styled(Label)`
  margin-bottom: 10px;
`;

const Container = styled.div`
  background-color: ${colors.black05};
  border-right: 1px solid ${colors.black25};
  overflow-y: scroll;
  padding: 16px 16px 16px 24px;
  flex-basis: 320px;
  flex-shrink: 1;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.scrollbarGray};
    border-radius: 5pt;
  }

  & > * {
    margin: 8px 0;
  }

  & > *:first-child {
    margin-top: 0px;
  }

  & > *:last-child {
    margin-bottom: 0px;
  }
`;

const EmptyState = styled.div`
  height: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
`;

const CardContainer = styled.div<{ $active: boolean }>`
  background-color: ${colors.white};
  border: 1px solid ${({ $active }) => ($active ? colors.primaryBlue : colors.black25)};
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  display: flex;
  flex-direction: column;
  min-height: 48px;

  padding: 16px;
`;

const BottomInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
`;

const CardHeader = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`;

const CardTitle = styled(LabelBold)`
  display: flex !important;
  margin-bottom: 8px !important;
`;
