import styled from 'styled-components';

import colors from 'styles/theme/colors';
import { LabelSmall } from 'styles/typography';

type Props = {
  text?: string;
  renderIcon?: () => React.ReactNode;
  $backgroundColor?: string;
  $color?: string;
  dataCy?: string;
};

export default function CardBadge({ text, renderIcon, $backgroundColor, $color, dataCy }: Props) {
  return (
    <Container $backgroundColor={$backgroundColor} $color={$color} data-cy={dataCy}>
      {renderIcon && renderIcon()}
      {text && <Text title={text}>{text}</Text>}
    </Container>
  );
}

const Container = styled.div<{ $backgroundColor?: string; $color?: string }>`
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  background: ${({ $backgroundColor }) => $backgroundColor || colors.black05};
  color: ${({ $color }) => $color || colors.black};
  padding: 0px 5px;
  gap: 5px;
`;

const Text = styled(LabelSmall)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
