export default function PriorityNoteFlag({ width = 8, height = 8, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 8 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      data-cy='priority-note-flag'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.91958 3.60754C6.616 3.6145 7.33995 3.62173 8 3.02273L6.27826 0C5.64185 0.598945 4.93089 0.591771 4.24161 0.584816C3.40518 0.576375 2.60066 0.568257 2 1.64394L3.66956 4.66667C4.27018 3.59107 5.07462 3.5991 5.91958 3.60754ZM0.0501138 1.39866L3.68251 7.75712C3.83818 8.00168 4.14953 8.05059 4.40899 7.95276C4.66844 7.80603 4.72034 7.51256 4.61655 7.26801L0.984158 0.909547C0.828484 0.664991 0.517136 0.61608 0.257679 0.713902C-0.0017775 0.860636 -0.0536689 1.1541 0.0501138 1.39866Z'
        fill='#D10D44'
      />
    </svg>
  );
}
